<template>
  <div class="container">
    <div>
      <vs-button @click="showNewIntervention = true">
        Nueva Intervención
      </vs-button>
    </div>
    <div>
      <h2>Mis Intervenciones</h2>
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>
              Intervención
            </vs-th>
            <vs-th>
              Fecha
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            ref="expandInfo"
            :key="i"
            v-for="(tr, i) in interventions"
            :data="tr"
          >
            <vs-td>
              {{ tr.name }}
            </vs-td>
            <vs-td>
              {{ tr.surgeryDate }}
            </vs-td>
            <template #expand>
              <div class="con-content">
                <vs-row class="userData">
                  <vs-col
                    :w="12 / tr.results.length"
                    v-for="(result, ind) in tr.results"
                    :key="'result' + ind"
                  >
                    <vs-button
                      flat
                      :success="result.result_id !== null && result.status >= 3"
                      :active="result.result_id !== null && result.status >= 3"
                      :disabled="
                        currentDate < result.activateDay ||
                          (result.result_id !== null && result.status == 5)
                      "
                      :danger="currentDate < result.activateDay"
                      @click="openProtocol(tr.protocol, result, tr, i)"
                    >
                      {{ result.name }}
                    </vs-button>
                  </vs-col>
                </vs-row>
              </div>
            </template>
          </vs-tr>
        </template>
        <template #notFound>
          No hay ningúna intervención activa
        </template>
      </vs-table>
    </div>

    <vs-dialog width="300px" not-center v-model="showNewIntervention">
      <template #header>
        <h4 class="not-margin">Ingrese el <b>Código</b> de la Intervención:</h4>
      </template>

      <div class="con-content">
        <vs-input
          class="inputForm"
          v-model="code"
          label="Código:"
          placeholder="#123423"
        >
          <template v-if="!code || code === ''" #message-danger>
            El Código se envia por Correo Electrónico
          </template>
        </vs-input>
      </div>
      <div>
        <vs-checkbox v-model="remember">
          <small
            >Acepto ceder el acceso a mi perfil personal al medico especialista
            que me ha enviado el código de esta intervención, según&nbsp;<a
              style="margin: 0 10px;"
              href="/politic"
              >la política de privacidad de traumareports</a
            >
          </small>
        </vs-checkbox>
      </div>

      <template #footer>
        <div class="dialogActionBtns">
          <vs-button @click="showNewIntervention = false" danger transparent>
            Cancelar
          </vs-button>
          <vs-button
            @click="addIntervention"
            :disabled="!remember"
            success
            transparent
          >
            Añadir
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog width="80%" not-center v-model="showIntervention">
      <template #header>
        <h4 class="not-margin">
          <b> {{ currentProtocol.name || "Nombre" }} : </b>
        </h4>
      </template>

      <div class="con-content">
        <div
          class="basicInfo"
          v-if="
            currentProtocolName === 'Pre Operatorio' &&
              currentProtocol &&
              currentProtocol.standards &&
              Object.values(currentProtocol.basics).some(a => a.status)
          "
        >
          <h4>Información básica</h4>
          <vs-switch
            v-if="currentProtocol.basics && currentProtocol.basics.addGender"
            class="inputForm"
            color="#7d33ff"
            v-model="answers.basics.gender"
          >
            <template #off>
              Hombre
            </template>
            <template #on>
              Mujer
            </template>
            <template #circle>
              <i v-if="!answers.basics.gender" class="fas fa-mars"></i>
              <i v-else class="fas fa-venus"></i>
            </template>
          </vs-switch>
          <vs-input
            v-if="currentProtocol.basics && currentProtocol.basics.addYears"
            class="inputForm"
            v-model="answers.basics.years"
            label-placeholder="Edad:"
            type="number"
          >
            <template v-if="answers.basics.years === ''" #message-danger>
              Requerido
            </template>
          </vs-input>
          <vs-input
            v-if="
              currentProtocol.basics && currentProtocol.basics.addDiagnostic
            "
            class="inputForm"
            v-model="answers.basics.diagnostic"
            label-placeholder="Diagnostico:"
            type="number"
          >
            <template v-if="answers.basics.diagnostic === ''" #message-danger>
              Requerido
            </template>
          </vs-input>
          <vs-input
            v-if="currentProtocol.basics && currentProtocol.basics.addHeight"
            class="inputForm"
            v-model="answers.basics.height"
            label-placeholder="Altura:"
            type="number"
          >
            <template v-if="answers.basics.height === ''" #message-danger>
              Requerido
            </template>
          </vs-input>
          <vs-input
            v-if="currentProtocol.basics && currentProtocol.basics.addWeight"
            class="inputForm"
            v-model="answers.basics.weight"
            label-placeholder="Peso:"
            type="number"
          >
            <template v-if="answers.basics.weight === ''" #message-danger>
              Requerido
            </template>
          </vs-input>
        </div>

        <div
          class="customInfo"
          v-if="
            currentProtocol &&
              currentProtocol.questions &&
              currentProtocol.questions.length > 0
          "
        >
          <h4>Preguntas personalizadas</h4>
          <div
            v-for="(question, ind) in currentProtocol.questions"
            :key="'customans_' + ind"
          >
            <vs-input
              class="inputForm"
              v-if="question.type === '1'"
              v-model="answers.custom[ind].answer"
              :label="question.text"
              placeholder="Respuesta:"
            />
            <small>{{ question.text }}</small>
            <vs-switch
              v-if="question.type === '4'"
              color="#7d33ff"
              v-model="answers.custom[ind].answer"
            >
              <template #off>
                Si
              </template>
              <template #on>
                No
              </template>
              <template #circle>
                <i
                  v-if="!answers.custom[ind].answer"
                  class="fas fa-check-circle"
                ></i>
                <i v-else class="far fa-times-circle"></i>
              </template>
            </vs-switch>
          </div>
        </div>

        <div
          class="stdInfo"
          v-if="
            currentProtocol &&
              currentProtocol.standards &&
              Object.values(currentProtocol.standards).some(a => a.status)
          "
        >
          <h4>Protocolos estándart</h4>
          <div
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addAkss.status
            "
            class="standartProtocol"
          >
            AKSS
          </div>
          <div
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addEva.status
            "
            class="standartProtocol"
          >
            EVA
          </div>
          <div
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addKnee.status
            "
            class="standartProtocol"
          >
            KNEE SS

            <div
              v-for="(chapter, ind) in standardProtocols[1].form.chapters"
              :key="'chapter_' + ind"
              class="standartProtocolCnt"
            >
              {{ chapter.name }}
              <div
                style="text-align:start"
                v-for="(field, ind2) in chapter.fields"
                :key="'field_' + ind2"
              >
                {{ !field.forDoctor ? field.title : null }}
                <vs-input
                  v-if="
                    !field.forDoctor &&
                      ['text', 'number', 'date'].includes(field.type)
                  "
                  :type="field.type || 'text'"
                  class="inputForm"
                  :label="field.name"
                  v-model="
                    answers.standards[1].form.chapters[ind].fields[ind2].answer
                  "
                />
                <vs-select
                  class="inputForm"
                  v-else-if="field.type === 'dropdown'"
                  :label="field.name"
                  v-model="
                    answers.standards[1].form.chapters[ind].fields[ind2].answer
                  "
                >
                  <vs-option
                    v-for="(option, ind) in field.options"
                    :key="'option_' + ind"
                    :label="option.text"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </vs-option>
                </vs-select>
              </div>
            </div>
            <small>{{ standardProtocols[1].form.copyright }}</small>
          </div>
          <div
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addOxford.status
            "
            class="standartProtocol"
          >
            OXFORD
          </div>
          <div
            v-if="
              currentProtocol.standards &&
                currentProtocol.standards.addWomac.status
            "
            class="standartProtocol"
          >
            WOMAC
          </div>
        </div>

        <div class="noStdInfo">
          <h4>Protocolos personalizados</h4>
          <div
            v-for="(noStan, ind2) in answers.noStandards"
            :key="'nonStand_protocol' + ind2"
          >
            <div class="standartProtocol" v-if="noStan.periods && noStan.periods.includes(currentProtocolName)">
              {{ noStan.name }}
              <div
                v-for="(chapter, ind) in noStan.form.chapters"
                :key="'chapter_' + ind"
                class="standartProtocolCnt"
              >
                {{ chapter.name }}
                <div
                  style="text-align:start"
                  v-for="(question, indq2) in chapter.questions"
                  :key="'question_' + indq2"
                >
                  {{ !question.forDoctor ? question.title : null }}
                  <vs-input
                    v-if="
                      !question.forDoctor &&
                        ['text', 'number', 'date'].includes(question.type)
                    "
                    :type="question.type || 'text'"
                    class="inputForm"
                    :label="question.name"
                    v-model="question.answer"
                    @change="
                     var obj = {}
                     obj[noStan.name] = question.answer
                      points[ind2][indq2] = obj
                      "
                  />
                  <vs-select
                    class="inputForm"
                    v-else-if="
                      !question.forDoctor && question.type === 'dropdown'
                    "
                    :label="question.name"
                    v-model="noStan.form.chapters[ind].questions[indq2].value"
                    @change="
                      totalPoints[ind2] = parseInt(
                        noStan.form.chapters[ind].questions[indq2].value
                      )
                      var obj = {}
                      obj[noStan.name]= !isNaN(obj[noStan.name]) ? 
                      obj[noStan.name] + parseInt(
                        noStan.form.chapters[ind].questions[indq2].value
                      )
                      : parseInt(
                        noStan.form.chapters[ind].questions[indq2].value
                      )
                    
                      obj[noStan.name] +=  points[ind2] && points[ind2][Object.keys(obj)] ?  points[ind2][Object.keys(obj)] : 0;
                    
                      points[ind2] = obj
                      
                    "
                  >
                    <vs-option
                      v-for="(option, ind) in question.options"
                      :key="'option_' + ind"
                      :label="option.name"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </vs-option>
                  </vs-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex">
        <vs-checkbox v-model="okLegal">
          {{
            "Autorizo la cesión y uso de mis datos médicos a los profesionales médicos autorizados respetando siempre las "
          }}&nbsp;
          <a href="https://traumareports.com/politic">
            {{ "Condiciones de uso y privacidad." }} </a
          >
        </vs-checkbox>
      </div>
      <template #footer>
        <div class="dialogActionBtns">
          <vs-button @click="showIntervention = false" danger transparent>
            Cancelar
          </vs-button>
          <vs-button
            :disabled="!okLegal"
            @click="onCompleteForm"
            success
            :transparent="!okLegal"
          >
            Enviar
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { patientMix, protocolMix, authMix } from "@/mixins/mixins.js";
import axios from "axios";

export default {
  name: "InterventionsPage",
  mixins: [patientMix, protocolMix, authMix],
  data() {
    return {
      code: "",
      okLegal: false,
      result: 1,
      index: 0,
      remember: false,
      totalPoints: new Array(15).fill(0),
      points:new Array(15).fill(0),
      selectedProtocol: null,
      currentProtocolName: "",
      showNewIntervention: false,
      showIntervention: false,
      currentProtocol: [],
      currentDate: new Date(),
      interventions: [],
      standardProtocols: [],
      answers: {
        basics: {
          gender: false,
          years: "",
          diagnostic: "",
          height: "",
          weight: ""
        },
        custom: [],
        standards: [],
        noStandards: []
      }
    };
  },
  methods: {
    async initData() {
      const loading = this.$vs.loading({
        color: "#fff",
        background: "#17a2b8"
      });
      const { currentUserInfo } = this.$store.state;
      const { uid } = currentUserInfo.user || currentUserInfo;
      this.interventions = await this.getInterventions(uid);
      this.standardProtocols = await this.getStandardProtocols();
      const userData = await this.firebaseGetUser(uid);
      this.answers.standards = this.standardProtocols.filter(
        protocol => protocol.isStandard
      );
      this.answers.noStandards = this.standardProtocols.filter(
        protocol => !protocol.isStandard
      );
      this.answers.basics = {
        gender: userData.gender,
        years: this.getYears(userData.birthDate),
        diagnostic: "",
        height: "",
        weight: ""
      };
      loading.close();
    },

    getYears(birdthDate) {
      const originDate = new Date(birdthDate).getFullYear();
      const today = new Date().getFullYear();
      return birdthDate ? today - originDate : 30;
    },

    async openProtocol(protocol_id, result, selectedProtocol, index) {
      if (result.status === 2 || !result.result_id) {
        this.selectedProtocol = selectedProtocol;
        this.currentProtocolName = result.name;
        const protocol = await this.getProtocol(protocol_id);
        this.currentProtocol = protocol[0];
        this.answers.custom = protocol[0].questions;
        this.answers.noStandards = protocol[0].noStandards.filter(
          a => a.status
        );
        const aStan = [];
        if (this.currentProtocol.standards.addKnee.status) {
          let selectedStand = this.standardProtocols.filter(
            a => a.name === "KNEE SOCIETY SCORE: POST-OP"
          );
          selectedStand.length > 0 ? aStan.push(selectedStand[0]) : null;
        }
        this.answers.standards = aStan;
        this.showIntervention = true;
        this.index = index;
      }
    },

    async onCompleteForm() {
      const data = this.answers;
      const ind = this.selectedProtocol.results.findIndex(
        res => res.name === this.currentProtocolName
      );
      // const interventionIndex = this.interventions.findIndex(
      //   (int) => int.id === this.selectedProtocol.id
      // );
      // const interv = this.interventions.find(
      //   (int) => int.id === this.selectedProtocol.id
      // );

      if (ind > -1) {
        const { currentUserInfo } = this.$store.state;
        const uid = currentUserInfo.uid
          ? currentUserInfo.uid
          : currentUserInfo.user.uid;
        // const { uid } = this.$store.state.currentUserInfo;
        const result_id = await this.addResult(data);
        this.selectedProtocol
        this.selectedProtocol.results[ind].result_id = result_id.id;
        this.selectedProtocol.results[ind].status += 1;
        this.selectedProtocol.results[ind].points = this.totalPoints.reduce(
          (pSum, a) => pSum + a,
          0
        );
        this.selectedProtocol.results[ind].points2 = this.points
        // this.selectedProtocol.results;
        this.toggleCollapse(this.index);
        const isUpdated = await this.updateProtocol(
          uid,
          this.selectedProtocol.id,
          this.selectedProtocol
        );

        if (isUpdated) {
          // this.interventions.splice(interventionIndex, 1, interv);
          this.$vs.notification({
            color: "success",
            position: "top",
            title: "Guardado con exito",
            text: "Se ha guardado la intervención con exito"
          });
        } else {
          this.$vs.notification({
            color: "danger",
            position: "top",
            title: "Error al guardar",
            text:
              "Se ha detectado un error al guardar la intervención, si el error persiste comunicate con el administrador."
          });
        }
      }
      this.showIntervention = false;
    },

    toggleCollapse(ind) {
      const { expandInfo } = this.$refs;
      expandInfo[ind].$el.click();
      expandInfo[ind].$el.click();
    },

    async addIntervention() {
      this.showNewIntervention = false;
      try {
        const intervention = await this.getFilteredInterventions(
          "wCWNQrCfmF9wGHiKI3IJ",
          this.code.trim(),
          true
        );
        const { currentUserInfo } = this.$store.state;
        const { uid } = currentUserInfo.user || currentUserInfo;
        this.interventions.push(intervention);
        await this.assignProtocol(uid, intervention);
        await this.assignPatient(intervention.created_by, { patient: uid });
        this.code = "";
        this.$vs.notification({
          color: "success",
          position: "top",
          title: "Añadido con exito",
          text: "Se ha añadido la intervención con exito"
        });
        const scheduleURL =
          "http://ec2-52-214-183-73.eu-west-1.compute.amazonaws.com:5000/api/schedule";

        const scheduleBody = {
          userId: uid
        };
        axios.post(scheduleURL, scheduleBody);
      } catch (error) {
        this.$vs.notification({
          color: "danger",
          position: "top",
          title: "Error al añadir",
          text:
            "Se ha detectado un error al añadir la intervención, si el error persiste comunicate con el administrador."
        });
      }
    }
  },
  created() {
    this.initData();
  }
};
</script>

<style scoped>
.container {
  text-align: justify;
  padding: 4rem 1rem 4rem 4.5rem;
}

.standartProtocol {
  background: #17a2b8;
  border: 2px solid #17a2b8;
  border-radius: 0.5rem;
  padding: 0.5rem 0rem;
  color: whitesmoke;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.standartProtocolCnt {
  background: #c8e1e6;
  padding: 1rem 0rem;
  color: black;
  border-bottom: 10px solid #17a2b8;
}

.basicInfo,
.customInfo,
.stdInfo,
.noStdInfo {
  border: 3px solid #17a2b8;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.dialogActionBtns {
  display: flex;
  justify-content: space-between;
}

.userInfo {
  text-align: start;
}

.inputForm {
  margin: 4rem 0.5rem;
  min-width: 95%;
}
</style>
<style>
.vs-select__options__content > button:nth-child(2n + 1) {
  background-color: #e9f4f5;
}
.vs-select__option {
  overflow: visible;
  max-height: none;
}

.inputForm > div > input {
  width: 100%;
}
.inputForm > div > .vs-select__input {
  width: 100%;
  min-width: none;
}

.vs-select.vs-select--state-null {
  display: flex;
  flex-direction: column-reverse;
}

.vs-select.vs-select--state-null > input {
  margin: -1.5rem;
}

.vs-select.vs-select--state-null > i {
  bottom: 0;
}

.vs-select__label {
  position: relative;
}
</style>
